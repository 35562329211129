import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { useStaticQuery, Link, graphql } from "gatsby"

const Container = styled.div`
  margin: 1rem auto;
  max-width: 800px;
`
const MenuItem = styled.li`
  display: inline-block;
  margin-right: 1rem;
`

const Menu = styled.ul`
  liststyle: none;
  float: right;
`

const Header = styled.header`
  margin-bottom: 1.5rem;
`
const PageTitle = styled.h3`
  display: inline;
`

const ListLink: FunctionComponent<{ to: string }> = props => (
  <MenuItem>
    <Link to={props.to}>{props.children}</Link>
  </MenuItem>
)

const Layout: FunctionComponent = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  )

  return (
    <Container>
      <header>
        <PageTitle>{data.site.siteMetadata.title}</PageTitle>
        <Menu>
          <ListLink to="/">Hjem</ListLink>
          <ListLink to="/kontakt">Kontakt</ListLink>
          <ListLink to="/about">Om eddern</ListLink>
        </Menu>
      </header>
      {children}
    </Container>
  )
}

export default Layout
